<template>
    <div class="divOne">
        <div class="dhDiv" v-show="flag1">
            <div class="content">
                <div style="width: 200px">
                    <p class="pYan1" style="text-align: center">
                        18665393712
                    </p>
                </div>
            </div>
        </div>

        <img
            src="../../assets/dianhua.png"
            id="imgDi1"
            class="imgDi"
            @click="dhClick"
        />

        <div style="margin-top: 20px"></div>

        <div class="imgWDiv" v-show="flag2">
            <div class="content">
                <div class="contenImg">
                    <p class="pYan2">微信号</p>
                    <div style="margin-top: 20px; margin-bottom: 20px">
                        <div style="">
                            <img src="../../assets/tu.jpg" />
                        </div>
                        <p
                            style="text-align: center; margin-top: 20px;"
                            class="pYan2"
                        >
                            扫码咨询
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <img
            src="../../assets/wxin1.png"
            id="imgW1"
            class="imgW"
            @click="wxinClick"
        />
        
     

        <div class="liuyanDiv" v-show="flag3" id="liuyanDiv" ref="liuyanDivRef">
            <div class="contentNo">
                <div style="width: 600px">
                    <p class="pYan3">
                        留言 【请留下您的手机号码或微信号，可以更有效的沟通】
                    </p>
                    <div style="margin-top: 20px; margin-bottom: 20px">
                        <el-input
                            placeholder="请输入咨询问题"
                            v-model="shuru.content"
                            type="textarea"
                            rows="8"
                            :autosize="{ minRows: 8, maxRows: 30 }"
                        />

                        <br />
                    </div>
                    <div>
                        <span class="sWen">* 手机号</span
                        ><el-input
                            placeholder="请输入手机号"
                            v-model="shuru.mobile"
                            style="width: 36%"
                        />
                        <span class="sWen" style="margin-left: 23px"
                            >微信号 </span
                        ><el-input
                            placeholder="请输入微信"
                            v-model="shuru.wechat"
                            style="width: 36%"
                        />
                    </div>
                    <div
                        style="
                            width: 100%;
                            border-top: 1px solid #ffffff;
                            height: 40%;
                            margin-top: 20px;
                        "
                    >
                        <div style="text-align: center; margin-top: 20px">
                            <button @click="submit" class="queding1">
                                确定
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contentShiPei">
                <div style="width: 100%">
                    <p class="pYan3">
                        留言 【请留下您的手机号码或微信号，可以更有效的沟通】
                    </p>
                    <div style="margin-top: 20px; margin-bottom: 20px">
                        <el-input
                            @click.native="shuruDiv"
                            placeholder="请输入咨询问题"
                            v-model="shuru.content"
                            type="textarea"
                            rows="11"
                            :autosize="{ minRows: 11, maxRows: 32 }"
                        />

                        <br />
                    </div>
                    <div>
                        <div style="">
                            <span style="font-size:36px">* 手机号</span
                            ><el-input
                                @click.native="shuruDiv"
                                placeholder="请输入手机号"
                                v-model="shuru.mobile"
                                style="width: 80%;margin-left:20px"
                            />
                        </div>
                        <div style="margin-top:40px">
                            <span style="font-size:36px;margin-left:28px">
                                微信号 </span
                            ><el-input
                                @click.native="shuruDiv"
                                placeholder="请输入微信"
                                v-model="shuru.wechat"
                                style="width: 80%;margin-left:9px"
                            />
                        </div>
                    </div>
                    <div
                        style="
                            width: 100%;
                            border-top: 1px solid #ffffff;
                            height: 40%;
                            margin-top: 20px;
                        "
                    >
                        <div style="text-align: center; margin-top: 20px">
                            <button @click="submit" class="queding2">
                                确定
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img
            src="../../assets/liuyan.png"
            id="imgL1"
            @click="enter"
            class="imgL"
        />
    

    
        <div class="taobaoDiv" v-show="flag4">  
            <div class="content">  
                <div style="width: 200px">  
                    <p class="pYan4" style="text-align: center">  
                        网飞物联  
                    </p>  
                </div>  
            </div>  
        </div>  

        <img  
            src="../../assets/taobao.png"  
            id="imgTaobao"  
            class="imgTaobao"  
            @click="taobaoClick"  
        />
    </div> 
</template>

<script></script>

<script>
export default {
    name: "RightLian",

    data() {
        return {
            flag1: false,
            flag2: false,
            flag3: false,
            flag4: false,
            shuru: {
                content: "",
                mobile: "",
                wechat: ""
            }
        };
    },
    methods: {
        shuruDiv() {
            this.$refs.liuyanDivRef.style.top = "720px";
        },
        enter() {
            this.flag3 = !this.flag3;
            this.flag2 = false;
            this.flag1 = false;
            let width1;
            if (window.innerWidth) {
                width1 = window.innerWidth;
            } else if (document.body && document.body.clientWidth) {
                width1 = document.body.clientWidth;
            }
            let heigth1;
            if (window.innerHeight) {
                heigth1 = window.innerHeight;
            } else if (document.body && document.body.clientHeight) {
                heigth1 = document.body.clientHeight;
            }

            if (
                document.documentElement &&
                document.documentElement.clientHeight &&
                document.documentElement.clientWidth
            ) {
                width1 = document.documentElement.clientWidth;
                heigth1 = document.documentElement.clientHeight;
            }
            console.log(width1 < 1100);

            if (width1 < 1100) {
                this.$refs.liuyanDivRef.style.top = "1100px";
            }

            //
        },
        wxinClick() {
            this.flag2 = !this.flag2;
            this.flag3 = false;
            this.flag1 = false;
        },
        dhClick() {
            this.flag1 = !this.flag1;
            this.flag2 = false;
            this.flag3 = false;
        },
        taobaoClick() {  
            // 跳转到淘宝店铺  
            window.open('https://shop119619774.taobao.com/?spm=pc_detail.29232929/evo365560b447259.shop_block.dshopinfo.66027dd60fYAj3', '_blank');  
        },  
        imgDianJi() {
            document.addEventListener("click", e => {
                const imgW = document.getElementById("imgW1");
                if (imgW) {
                    if (!imgW.contains(e.target)) {
                        this.flag2 = false;
                    }
                }
                const imgL = document.getElementById("imgL1");
                if (imgL) {
                    if (!imgL.contains(e.target)) {
                        this.flag3 = false;
                    }
                }
                const imgDi = document.getElementById("imgDi1");
                if (imgDi) {
                    if (!imgDi.contains(e.target)) {
                        this.flag1 = false;
                    }
                }

                const liuyanDiv = document.getElementById("liuyanDiv");
                if (liuyanDiv) {
                    if (liuyanDiv.contains(e.target)) {
                        this.flag3 = true;
                    }
                }
                const imgTaobao = document.getElementById("imgTaobao");
                if (imgTaobao) {
                    if (!imgTaobao.contains(e.target)) {
                        this.flag4 = false;
                    }
                }

                /*if (!this.$refs.imgL1.contains(e.target)) {
                }

                if (!this.$refs.imgDi1.contains(e.target)) {
                    this.flag1 = false;
                }*/
            });
        },
        /* */
        submit() {
            let that = this;
            that.axios({
                url: "/index/feedback/submit",
                method: "post",
                data: {
                    content: that.shuru.content,
                    mobile: that.shuru.mobile,
                    wechat: that.shuru.wechat
                }
            }).then(res => {
                if (res.data.status === 0) {
                    that.$message({
                        message: res.data.msg,
                        type: "error"
                    });
                } else {
                    that.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                    this.flag3 = false;
                }
            });
        }
    },

    mounted() {
        this.imgDianJi();
    }
};
</script>

<style lang="less">
.queding1 {
    font-size: 15px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    width: 65px;
    height: 44px;
    border: 0px solid #ffffff;
}
.queding2 {
    font-size: 36px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    width: 165px;
    height: 84px;
    border: 0px solid #ffffff;
}
.imgDi {
    width: 2%;
    display: inline-block;
    position: fixed;
    top: 220px;
    right: 3px;
}
.imgW {
    width: 2.5%;
    display: inline-block;
    position: fixed;
    top: 288px;
    right: 0px;
}
.imgL {
    width: 2%;
    display: inline-block;
    position: fixed;
    top: 360px;
    right: 5px;
}
.imgTaobao{
    width: 2.5%;
    display: inline-block;
    position: fixed;
    top: 425px;
    right: 1px;
}
.contenImg {
    width: 200px;
}
.el-tooltip__popper {
    background-color: rgb(255, 141, 26) !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
    border-left-color: rgb(255, 141, 26) !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
    border-left-color: rgb(255, 141, 26) !important;
}

.imgWDiv {
    color: #ffffff;

    padding: 10px;
    background-color: rgb(255, 141, 26) !important;
    position: fixed;
    right: 40px;
    top: 200px;
    border-radius: 4px;
    line-height: 1.2;
}
.liuyanDiv {
    color: #ffffff;

    padding: 10px;
    background-color: rgb(255, 141, 26) !important;
    position: fixed;
    right: 40px;
    top: 165px;
    border-radius: 4px;
    line-height: 1.2;
}
.dhDiv {
    color: #ffffff;
    padding: 10px;
    background-color: rgb(255, 141, 26) !important;
    position: fixed;
    right: 40px;
    top: 220px;
    border-radius: 4px;
    line-height: 1.2;
}

.liuyuanButton {
    position: fixed;
    right: 0px;
    top: 400px;
}
.liuTwoDiv {
    padding: 10px;
    background-color: rgb(255, 141, 26) !important;
}
.liuyuanButton:hover + .liuyanDiv {
    display: block;
}
.liuyanDiv:hover {
    display: block;
}
</style>

<style scoped></style>
<style>
.tips {
    font-size: 16px;
    margin-right: 10px;
}

.sWen {
    font-size: 16px;
    margin-right: 10px;
}
.pYan {
    font-size: 16px;
}
.pYan1 {
    font-size: 16px;
}
.pYan2 {
    font-size: 16px;
}
.pYan3 {
    font-size: 16px;
}

.divOne {
    position: fixed;
    top: 260px;
    right: 0;
    z-index: 99;
    color: black;
}
</style>
<style>
@media screen and (min-width: 1100px) {
    .contentShiPei {
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    ::v-deep .el-input__inner {
        height: 80px;
        font-size: 24px;
        color: #000000;
    }
    .imgL {
        top: 1200px;
        width: 8%;
    }
    .liuyanDiv {
        height: 690px;
        width: 80%;
        top: 1100px;
        right: 125px;
    }
    .imgW {
        top: 1000px;
        width: 9.8%;
        margin-right: -0.5%;
    }

    .imgWDiv {
        width: 400px;
        height: 540px;
        top: 890px;
        right: 125px;
    }
    .imgtaobao{
        top: 1000px;
        width: 9.8%;
        margin-right: -0.5%;
    }
    .contentNo {
        display: none;
    }
    .contenImg {
        width: 400px;
    }
    .pYan2 {
        font-size: 36px;
    }
    .pYan3 {
        font-size: 36px;
    }

    .imgDi {
        top: 800px;
        width: 8%;
    }
    .dhDiv {
        width: 300px;
        height: 80px;
        top: 820px;
        right: 125px;
    }
    .pYan1 {
        margin-top: 5px;
        margin-left: 30px;
        font-size: 36px;
    }

    .content > div {
        width: 100%;
    }
    .content {
        width: 100%;
    }
}
</style>
